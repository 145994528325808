.panelWrapper {
	grid-area: 1 / -1;

	/**
	 * This is a hack to make the panel contents horizontally scroll when there is overflow
	 */
	min-width: 1px;
}

.panel[hidden] {
	display: block;
}
