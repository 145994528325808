.tabsContainer {
	grid-column: 1/-1;
}

.tabsList {
	&.center {
		width: fit-content;
		max-width: 100%;
		margin: 0 auto;
	}
}

.skeleton {
	display: flex;
	align-items: center;
	justify-content: center;
	grid-column: 1/-1;

	/* hack - viewport height minus the amount of space above the panel */
	height: calc(100vh - 284px);
}
