.section {
	position: relative;
}

.backgroundImageWrapper {
	position: absolute;
	width: 100%;
	height: 100%;
	inset: 0;
	z-index: 0;
	display: none;

	@media (--medium-up) {
		display: block;
	}
}

.backgroundImage {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.root {
	composes: g-grid-container from global;
	position: relative;
	width: 100%;
}

.carouselContainer {
	position: relative;
	overflow: hidden;
	z-index: 1;
	border-radius: 8px;
}

.carousel {
	display: flex;
	gap: var(--hdsplus-spacing-04);
	list-style: none;
	margin: 0;
	padding: 0;
}

.item {
	position: relative;
	display: grid;
	width: 100%;
	flex-shrink: 0;
	border-radius: 8px;
	background: var(--token-color-surface-strong);
	box-shadow: var(--token-elevation-low-box-shadow);
}

.itemInner {
	width: 100%;
	display: grid;
	grid-template-rows: auto 1fr;
	grid-template-columns: 1fr;
	overflow: hidden;
	height: 100%;
	border-radius: 8px;

	@media (--medium-up) {
		grid-template-rows: 1fr;
		grid-template-columns: 2fr 3fr;
	}
}

.imageWrapper {
	/* 
		! HACK: Magic-ish number to normalize photo sizes. Derived from image sizes in the design here:
		https://www.figma.com/file/yILwDFNJpdpvpg3YP3gjNW/Materia-Design-System?node-id=4905%3A47445&mode=dev
	*/
	aspect-ratio: 342 / 200;
	position: relative;
	overflow: hidden;

	@media (--medium-up) {
		aspect-ratio: unset;
		height: 100%;
	}
}

.image {
	object-fit: cover;
	object-position: center;
	width: 100%;
	height: 100%;
}

.quoteWrapper {
	display: flex;
	overflow: hidden;
	align-items: center;
	border: 1px solid var(--token-color-border-primary);
	border-radius: 8px;
	padding: var(--hdsplus-spacing-08) var(--hdsplus-spacing-07)
		var(--hdsplus-spacing-09);

	@media (--small) {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		border-top: 0;
	}

	@media (--medium) {
		padding: var(--hdsplus-spacing-08) var(--hdsplus-spacing-09);
	}

	@media (--medium-up) {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		border-left: 0;
	}

	@media (--large) {
		padding: var(--hdsplus-spacing-13) var(--hdsplus-spacing-11);
	}
}

.textWrapper {
	display: flex;
	flex-direction: column;
	gap: var(--hdsplus-spacing-05);

	@media (--medium) {
		gap: var(--hdsplus-spacing-06);
	}

	@media (--large) {
		gap: var(--hdsplus-spacing-08);
	}
}

.text {
	@media (--medium-up) {
		font-size: var(--hdsplus-typography-display-300-font-size);
		line-height: var(--hdsplus-typography-display-300-line-height);
		letter-spacing: var(--hdsplus-typography-display-300-letter-spacing);
	}

	@media (--large) {
		font-size: var(--hdsplus-typography-display-400-font-size);
		line-height: var(--hdsplus-typography-display-400-line-height);
		letter-spacing: var(--hdsplus-typography-display-400-letter-spacing);
	}
}

.quotee {
	display: flex;
	gap: var(--hdsplus-spacing-05);
}

.quoteeName {
	composes: text;
	font-weight: 600;

	@media (--large) {
		font-weight: 700;
	}
}

.avatar {
	border-radius: 50%;
	height: 32px;
	width: 32px;
	object-fit: cover;
}

.controlsWrapper {
	position: relative;
	margin-top: var(--hdsplus-spacing-07);
	z-index: 1;

	@media (--medium-up) {
		margin-top: var(--hdsplus-spacing-09);
	}
}

.controls {
	--control-active-width: 56px;
	--control-inactive-width: 8.41px;
	--animation-duration: 10s;
	
	z-index: 1;
	padding: 0;
	list-style: none;
	display: flex;
	gap: 4px;
	justify-content: center;
	margin: 0;

	@media (--medium-up) {
		justify-content: flex-start;
	}
}

.control {
	display: flex;
	position: relative;
	height: 8px;
	width: auto;
	border-radius: 100px;
	overflow: hidden;
	background-color: var(--token-color-palette-blue-100);
}

.controlButton {
	composes: hds-focus-ring-basic from global;
	position: relative;
	border: none;
	box-shadow: none;
	cursor: pointer;
	background: transparent;
	min-width: var(--control-inactive-width);
	transition: min-width linear 0.2s,
		background-color linear var(--animation-duration);
	background-color: var(--token-color-palette-blue-100);
	height: 8px;

	&.isActive {
		min-width: var(--control-active-width);

		&::after {
			content: '';
			width: 0;
			height: 100%;
			min-width: 15%;
			margin-left: -15%;
			border-radius: 10px;
			background-color: var(--token-color-foreground-action);
			position: absolute;
			top: 0;
			left: 0;
			animation-name: progress;
			animation-duration: var(--animation-duration);
			transition: background-color ease-in-out 0.2s;
		}
	}

	&::before {
		content: '';
		position: absolute;
		padding: var(--hdsplus-spacing-01);
		inset: 0;
	}

	&.isPaused {
		&::after {
			animation-play-state: paused;
		}
	}
}

@keyframes progress {
	from {
		width: 0;
	}
	to {
		width: 115%;
	}
}
