.video {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	/* Apply border radius to Wistia play button */
	& :global(.w-bpb-wrapper) {
		border-radius: var(--hdsplus-spacing-01) !important;
		overflow: hidden;
	}
}

.videoInner {
	width: 100%;
	height: 100%;
	aspect-ratio: 16 / 9;
	border-radius: var(--hdsplus-spacing-03);
	background-color: var(--token-color-foreground-faint);
	overflow: hidden;
	position: relative;
}
